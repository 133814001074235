<template>
  <div>
    <vx-card actionable class="cardx">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <!-- <datepicker
            :disabled-dates="disabledates"
            placeholder="Select Date"
            v-model="selected_date"
          ></datepicker> -->
        </vs-col>
      </vs-row>
      <vs-table :data="sortSpoc" style="margin-top: 3%;">
        <template slot="thead">
          <vs-th colspan="1"></vs-th>
          <vs-th colspan="2">CPA / CMA</vs-th>
          <vs-th colspan="2">IIML</vs-th>
          <vs-th colspan="2">PGDBS</vs-th>
          <vs-th colspan="2">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr>
            <vs-td>
              <div>
                <small><b>Spoc Name</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small><b>P2</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small><b>P3</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small><b>P2</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small><b>P3</b></small>
              </div> </vs-td
            ><vs-td>
              <div>
                <small><b>P2</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small><b>P3</b></small>
              </div>
            </vs-td>
            <vs-td>
              <div></div>
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr">
            <vs-td>
              <div>
                <small
                  ><b>{{ tr.first_name }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr["CPA,CMA"].P2) }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr["CPA,CMA"].P3) }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr.IIML.P2) }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr.IIML.P3) }}</b></small
                >
              </div> </vs-td
            ><vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr.PGDBS.P2) }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <small
                  ><b>{{ getSpocName(tr.PGDBS.P3) }}</b></small
                >
              </div>
            </vs-td>
            <vs-td>
              <div>
                <vs-button color="dark" type="border" @click="changeConfig(tr)"
                  >Edit</vs-button
                >
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <SpocFunctionEditPopup :all_spocs="all_spocs"></SpocFunctionEditPopup>
  </div>
</template>

<script>
import SpocFunctionEditPopup from "../components/pagesComponents/SpocFunctionEditPopup.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import VueApexCharts from "vue-apexcharts";
import eventbus from "../components/eventbus";
// import EventBus from "../components/eventbus";
export default {
  mounted() {
    eventbus.$on("reload-spocFunction", () => {
      this.sortSpocs(this.selected_city);
    });
    this.spocscopy = [];
    this.cities = [];
    this.spocs = [];
    this.getSpocs();
  },
  data() {
    return {
      open: false,
      activeLead: null,
      /* spocs: [
        {
          spoc_name: "John Random",
          cpa_cma: {
            p2: "cheese",
            p3: "ting"
          },
          iiml: {
            p2: "cheese",
            p3: "ting"
          },
          pgdbs: {
            p2: "cheese",
            p3: "ting"
          },
          editing: false
        }
      ], */
      spocscopy: [],
      cities: [],
      sortSpoc: [],
      rawBde: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      cityString: "",
      EnquirySpelling: "Enquiries",
      checkedEnquiries: [],
      SpocId: "",
      SpocName: "",
      MissedCall: "",
      UntrackedCall: "",
      ToUpdate: "",
      CallLogToday: "",
      UntappedData: "",
      DelaysData: "",
      TodayData: "",
      WaitingData: "",
      MhpsData: "",
      NetEnquiries: "",
      all_spocs: []
    };
  },
  watch: {
    selected_city: function(value) {
      if (value !== null) {
        this.sortSpocs(value);
      } else {
        this.sortSpoc = [];
      }
    }
  },
  methods: {
    getSpocName(spoc_id) {
      let name = "";
      this.all_spocs.forEach(spoc => {
        if (spoc.id === spoc_id) {
          name = spoc.first_name;
        }
      });
      return name;
    },
    changeConfig(activeLead) {
      // this.activeLead = activeLead;
      eventbus.$emit("edit-spoc-config", activeLead);
      // this.open = true;
      // spoc_object.editing = !spoc_object.editing;
    },
    removeUnwwantSpocs(SpocList) {
      let wantedSpocIds = [
        178,
        179,
        181,
        182,
        186,
        187,
        188,
        189,
        190,
        200
        // 56,
        // 111,
        // 69,
        // 192,
        // 84,
        // 83,
        // 191,
        // 193,
        // 43,
        // 103,
        // 135,
        // 133,
        // 134,
        // 89,
        // 94,
        // 54,
        // 195,
        // 76,
        // 75,
        // 100,
        // 180,
        // 96,
        // 176,
        // 174,
        // 194,
        // 196,
        // 99,
        // 46,
        // 197,
        // 80
      ];
      let filteredSpocs = SpocList.filter(function(el) {
        return wantedSpocIds.indexOf(el.id) <= 0;
      });
      return filteredSpocs;
    },
    extractSpocByCity(response) {
      var unique = response
        .map(name => {
          return {
            count: 1,
            name: name.city
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      if (localStorage.getItem("role") === "cm4") {
        this.selected_city = this.cities[0];
      } else if (localStorage.getItem("role") === "cm1") {
        this.SpocName.forEach(spoc => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
    },
    getData(spoc_id, category) {
      // console.log(spoc_id, category);
      let url = `${constants.SERVER_API}getSpocDashboardForAdminDetails`;
      let obj = {
        spoc_id: spoc_id,
        category: category
      };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log("mno", response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpocs() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          // console.log("ydf", response);
          this.loggedin = response.data.logged_in_user_id;
          response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
          this.SpocName = response.data.spocs;
          this.all_spocs = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async getAssignmentInfo(spocs) {
      this.$vs.loading({
        type: "default"
      });
      for (let i = 0; i < spocs.length; i++) {
        let response = await this.getSpocAssignmentInfo(spocs[i].id);
        // console.log(response);
        spocs[i]["CPA,CMA"] = response.data["CPA,CMA"];
        spocs[i].IIML = response.data.IIML;
        spocs[i].PGDBS = response.data.PGDBS;
      }
      this.$vs.loading.close();
      this.sortSpoc = spocs;
    },
    getSpocAssignmentInfo(spoc_id) {
      let url = `${constants.SERVER_API}getSpocAssignmentInfo?spoc_id=${spoc_id}`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`
            }
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return promise;
    },
    sortSpocs(value) {
      let sort = [];
      this.SpocName.forEach(spoc => {
        if (spoc.city === value) {
          sort.push(spoc);
        }
      });
      this.getAssignmentInfo(sort);
      // this.sortSpoc = sort;
    }
  },
  components: {
    DateRangePicker,
    VueApexCharts,
    "v-select": vSelect,
    SpocFunctionEditPopup
  }
};
</script>
<style></style>
