<template>
  <vs-popup
    v-if="open"
    class="holamundo"
    :title="'Lead management for ' + activeLead.first_name"
    :active.sync="open"
  >
    <vs-divider>
      <b>CPA / CMA</b>
    </vs-divider>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P2</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :options="all_spocs"
          :multiple="false"
          v-model="axios_obj.cpacma.P2"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:1000"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P3</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :multiple="false"
          :options="all_spocs"
          v-model="axios_obj.cpacma.P3"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:900"
        />
      </vs-col>
    </vs-row>
    <vs-divider>
      <b>IIML</b>
    </vs-divider>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P2</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :options="all_spocs"
          :multiple="false"
          v-model="axios_obj.iiml.P2"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:800"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P3</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :multiple="false"
          :options="all_spocs"
          v-model="axios_obj.iiml.P3"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:700"
        />
      </vs-col>
    </vs-row>
    <vs-divider>
      <b>PGDBS</b>
    </vs-divider>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P2</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :options="all_spocs"
          :multiple="false"
          v-model="axios_obj.pgdbs.P2"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:600"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
        <b style="color: gray">P3</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="10"
      >
        <v-select
          label="first_name"
          :multiple="false"
          :options="all_spocs"
          v-model="axios_obj.pgdbs.P3"
          :reduce="obj => obj.id"
          style="width:450px; position: fixed; z-index:500"
        />
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 3%">
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-button color="success" type="filled" @click="saveChanges"
          >Save</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import eventbus from "../eventbus";
import axios from "axios";
import constants from "../../../constants.json";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect
  },
  props: ["all_spocs"],
  data() {
    return {
      open: false,
      activeLead: {},
      axios_obj: {
        cpacma: {
          P2: "",
          P3: ""
        },
        iiml: {
          P2: "",
          P3: ""
        },
        pgdbs: {
          P2: "",
          P3: ""
        }
      }
    };
  },
  mounted() {
    eventbus.$on("edit-spoc-config", payload => {
      this.activeLead = payload;
      this.preloadOptions();
      this.open = true;
    });
  },
  methods: {
    preloadOptions() {
      //   console.log(this.activeLead);
      this.axios_obj.cpacma.P2 = this.activeLead["CPA,CMA"].P2;
      this.axios_obj.cpacma.P3 = this.activeLead["CPA,CMA"].P3;
      this.axios_obj.iiml.P2 = this.activeLead["IIML"].P2;
      this.axios_obj.iiml.P3 = this.activeLead["IIML"].P3;
      this.axios_obj.pgdbs.P2 = this.activeLead["PGDBS"].P2;
      this.axios_obj.pgdbs.P3 = this.activeLead["PGDBS"].P3;
    },
    saveChanges() {
      this.axios_obj.spoc_id = this.activeLead.id;
      console.log(this.axios_obj);
      axios
        .post(`${constants.SERVER_API}changeAssignment`, this.axios_obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          //   console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            eventbus.$emit("reload-spocFunction");
            this.open = false;
          }
          //   this.$vs.loading.close(this.$refs.loadableButton.$el);
        })
        .catch(error => {
          this.handleError(error);
          //   this.$vs.loading.close(this.$refs.loadableButton.$el);
        });
    }
  }
};
</script>

<style></style>
